import { useNavigate } from "react-router-dom";
import GuideRightContent from "./GuideRightContent";
import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide3() {
  const navigate = useNavigate();
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3">
              Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
            </h2>
          </div>
        </div>
      </section>
      <section className="hm-blog-grids pt-40 pb-120 overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-8">
              <div className="hm-blog-grid-left bg-white deep-shadow rounded-2">
                <div className="hm2-blog-card">
                  <div className="feature-img rounded-top overflow-hidden"></div>
                  <div className="bd-content-wrapper mt-0">
                    <div className="bd-blog-meta d-flex align-items-center flex-wrap mb-4">
                      <div className="bd-blog-author d-flex align-items-center me-5">
                        <img
                          src="assets/img/author-1.png"
                          alt="author"
                          className="rounded-pill img-fluid flex-shrink-0"
                        />
                        <div className="bd-blog-author-info ms-3">
                          <span>Đăng bởi</span>
                          <h6 className="mb-0 mt-1">Admin</h6>
                        </div>
                      </div>
                      <div className="bd-blog-categories me-5 mt-3 mt-md-0">
                        <span>Trong mục</span>
                        <h6 className="mb-0 mt-1">Tutorial</h6>
                      </div>
                      <div className="bd-blog-date mt-3 mt-md-0">
                        <span>Tạo ngày</span>
                        <h6 className="mb-0 mt-1">04/03/2020</h6>
                      </div>
                    </div>
                    <p>
                      <span style={{ fontSize: 14 }}>
                        <span style={{ color: "#c0392b" }}>
                          <strong>Cách 1: Dùng powershell</strong>
                        </span>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <strong>Bước 1 : Mở Powershell :</strong>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1. Nhấn các
                        phím Win+S để mở Search.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. Gõ
                        powershell vào hộp tìm kiếm, nhấp chuột phải&nbsp; trong
                        kết quả tìm kiếm và chọn Run as administrator.
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <strong>
                          Bước 2 : Mở rộng phân vùng : Copy dòng lệnh sau ,vào
                          cửa sổ PowerShell bên trong VPS sau đó ấn chuột phải
                          và Enter. &nbsp;&nbsp;{" "}
                        </strong>
                      </span>
                    </p>
                    <blockquote>
                      <p>
                        <span style={{ fontSize: 12 }}>
                          function List-Disks {"{"}
                          <br />
                          'list disk' | diskpart |<br />
                          &nbsp;? {"{"} $_ -match 'disk (\d+)\s+online\s+\d+
                          .?b\s+\d+ [gm]b' {"}"} |<br />
                          &nbsp;% {"{"} $matches[1] {"}"}
                          <br />
                          &nbsp;{"}"}
                          <br />
                          function List-Partitions($disk) {"{"}
                          <br />
                          "select disk $disk", "list partition" | diskpart |
                          <br />
                          &nbsp;? {"{"} $_ -match 'partition (\d+)' {"}"} |
                          <br />
                          &nbsp;% {"{"} $matches[1] {"}"}
                          <br />
                          {"}"}
                          <br />
                          function Extend-Partition($disk, $part) {"{"}
                          <br />
                          "select disk $disk","select partition $part","extend"
                          | diskpart | Out-Null
                          <br />
                          &nbsp;{"}"}
                          <br />
                          &nbsp;List-Disks | % {"{"}
                          <br />
                          &nbsp;$disk = $_
                          <br />
                          &nbsp;List-Partitions $disk | % {"{"}
                          <br />
                          &nbsp;Extend-Partition $disk $_
                          <br />
                          &nbsp;{"}"}
                          <br />
                          &nbsp;{"}"}
                        </span>
                      </p>
                    </blockquote>
                    <p>&nbsp;</p>
                    <p>
                      <span style={{ fontSize: 14 }}>
                        <strong>
                          <span style={{ color: "#c0392b" }}>
                            Cách 2: Dùng{" "}
                            <span
                              style={{
                                backgroundColor: "#fbfcfd",
                                fontFamily: '"Open Sans",sans-serif',
                              }}
                            >
                              Disk Management
                            </span>
                          </span>
                        </strong>
                      </span>
                    </p>
                    <p
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        textAlign: "start",
                      }}
                    >
                      <span style={{ fontSize: 12 }}>
                        <strong>BƯỚC 1</strong>: Nhấp chuột phải{" "}
                        <strong>Windows Start ( logo Windows ) </strong>ở dưới
                        cùng bên trái của màn hình và chọn&nbsp;
                        <strong>Disk Management</strong>.
                      </span>
                    </p>
                    <p
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        textAlign: "start",
                      }}
                    >
                      <span style={{ fontSize: 12 }}>
                        <strong>BƯỚC 2</strong>: Nhấp chuột phải vào phân vùng
                        cần mở rộng{" "}
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          và chọn “
                        </span>
                        <strong>Extend Disk</strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          "
                        </span>
                      </span>
                    </p>
                    <p
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        textAlign: "start",
                      }}
                    >
                      <span style={{ fontSize: 12 }}>
                        <img
                          alt=""
                          src="https://www.partition-magic-server.com/wp-content/uploads/2016/08/extend-partition-2016.png"
                          style={{width: '100%'}}
                        />
                      </span>
                    </p>
                    <p
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        textAlign: "start",
                      }}
                    >
                      <span style={{ fontSize: 12 }}>
                        <strong>BƯỚC 3</strong>:{" "}
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          Các&nbsp;
                        </span>
                        <strong>Extend Volume Wizard</strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          &nbsp;sẽ được đưa ra, nhấp vào{" "}
                        </span>
                        <strong>Next </strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          để tiếp tục.
                        </span>
                      </span>
                    </p>
                    <p
                      style={{
                        marginLeft: 0,
                        marginRight: 0,
                        textAlign: "start",
                      }}
                    >
                      <span style={{ fontSize: 12 }}>
                        <img
                          alt=""
                          src="https://www.partition-magic-server.com/wp-content/uploads/2016/08/extend-partition-2016_1-750x563.png"
                          style={{width: '100%'}}
                        />
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <strong>BƯỚC 4</strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          : Trong cửa sổ bật lên,
                        </span>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          {" "}
                        </span>
                        <span
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#333333",
                            fontFamily: "helvetica",
                          }}
                        >
                          tùy theo dung lượng cần mở rộng thêm để lựa chọn, nếu
                          không có mục đích gì đặc biệt, bạn nên để mặc định và{" "}
                        </span>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          nhấp vào{" "}
                        </span>
                        <strong>Next </strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          để tiếp tục.
                        </span>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <img
                          alt=""
                          src="https://www.partition-magic-server.com/wp-content/uploads/2016/08/extend-partition-2016_2-750x563.png"
                          style={{width: '100%'}}
                        />
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <strong>BƯỚC 5</strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          : Xác nhận hoạt động và nhấp vào “
                        </span>
                        <strong>Finish</strong>
                        <span
                          style={{
                            backgroundColor: "#fbfcfd",
                            color: "#424242",
                            fontFamily: '"Open Sans",sans-serif',
                          }}
                        >
                          " để hoàn thành.
                        </span>
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: 12 }}>
                        <img
                          alt=""
                          src="https://www.partition-magic-server.com/wp-content/uploads/2016/08/extend-partition-2016_3-750x563.png"
                          style={{width: '100%'}}
                        />
                      </span>
                    </p>
                    <p>&nbsp;</p>
                    <div className="bd-blog-bottom mt-4">
                      <div className="row g-4">
                        <div className="col-md-6">
                          <div className="bd-blog-share">
                            <h6>Share this Post</h6>
                            <div className="social-icons mt-3">
                              <a href="#" className="facebook">
                                <i className="fab fa-facebook-f" />
                              </a>
                              <a href="#" className="instagram">
                                <i className="fab fa-instagram" />
                              </a>
                              <a href="#" className="dribble">
                                <i className="fab fa-dribbble" />
                              </a>
                              <a href="#" className="behance">
                                <i className="fab fa-behance" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="bd-blog-tags">
                            <h6>Related Tags.</h6>
                            <div className="tags-wrapper mt-3">
                              <a href="#" className="mb-3">
                                Domain
                              </a>
                              <a href="#" className="mb-3">
                                Web Hosting
                              </a>
                              <a href="#" className="mb-3">
                                Security
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <GuideRightContent
                children={
                  <>
                    <li>
                      <a
                        onClick={() => {
                          navigate(URL.Guide1);
                        }}
                      >
                        Hướng Dẫn Tăng Cường Bảo Mật VPS
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigate(URL.Guide2);
                        }}
                      >
                        Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                      </a>
                    </li>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
