import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { get } from "lodash";
import request from "utils/request";

type Status = "idle" | "loading" | "success" | "error";

export interface PayloadActionGetListProxy {
  region: string;
}

export interface PayloadActionListProxyDetail {
  id: number;
  slug: string;
  name: string;
  description: string;
  price: number;
  cpu: string;
  ram: string;
  ssd: string;
  bandwidth: string;
  ethernet_port: string;
  ipv4: string;
  status: string;
  featured: boolean;
}

interface GetListProxyState {
  listProxyDetail: PayloadActionListProxyDetail[];
  paramsListProxyDetail: PayloadActionGetListProxy;
  statusLoadListProxyDetail: Status;
}

const initialState: GetListProxyState = {
  listProxyDetail: [],
  paramsListProxyDetail: {
    region: "",
  },
  statusLoadListProxyDetail: "idle",
};

export const actionGetListProxy = createAsyncThunk(
  "listProxy/actionGetListProxy",
  async (params: PayloadActionGetListProxy, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "api/plan/proxy",
        method: "GET",
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "proxyDetail",
  initialState,
  reducers: {
    actionResetStatus(state) {
      state.statusLoadListProxyDetail = "idle";
    },
    actionResetStore() {
      return { ...initialState };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetListProxy.pending, (state) => {
        state.statusLoadListProxyDetail = "loading";
      })
      .addCase(actionGetListProxy.fulfilled, (state, action) => {
        state.statusLoadListProxyDetail = "success";
        state.listProxyDetail = get(action, "payload.data").map(
          (item: PayloadActionListProxyDetail) => ({ ...item })
        );
      })
      .addCase(actionGetListProxy.rejected, (state) => {
        state.statusLoadListProxyDetail = "error";
      });
  },
});

export const { actionResetStatus, actionResetStore } = slice.actions;

export const selectListProxyDetail = (state: RootState) =>
  state.proxyDetail.listProxyDetail;
export const selectLoadingListProxyDetail = (state: RootState) =>
  state.proxyDetail.statusLoadListProxyDetail;

export default slice.reducer;
