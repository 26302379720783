import React from "react";

interface IGuide {
  children: React.ReactNode;
}
export default function GuideRightContent(props: IGuide) {
  const { children } = props;
  return (
    <div className="hm-blog-sidebar mt-4 mt-xl-0">
      <div className="sidebar-widget categories-widget">
        <h4 className="widget-title">Danh sách hướng dẫn</h4>
        <ul className="mt-30">
          {children}
        </ul>
      </div>
      <div className="sidebar-widget banner-widget">
        <div className="banner-content bg-primary-gradient rounded-2 text-center position-relative zindex-1 overflow-hidden">
          <h6 className="text-white">
            Để giúp bạn giải quyết vấn đề nhanh chóng
          </h6>
          <div className="banner-contact-info mt-30">
            <span className="icon-wrapper rounded-circle text-white d-inline-flex align-items-center justify-content-center">
              <i className="fa-solid fa-phone" />
            </span>
            <h4>
              <a href="tel:01234565299" className="text-white mt-20 d-block">
                Cal: + (84)973884503
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
