/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
      <div style={{ marginTop: "220px"}}>
        <>
          {/* <div
            id="pageTitle"
            className="HeaderAdjust"
            data-bg-src="img/background-img/page-title-bg.jpg"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3">
                  <div className="section-title">
                    <h2>blog</h2>
                  </div>
                  <ol className="breadcrumb">
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li className="active">Blog Page</li>
                  </ol>
                </div>
              </div>
            </div>
          </div> */}
            {/* Blog Area Start */}
            <div id="blog" className="page">
              <div className="container">
                <div className="row">
                  {/* Blog Content Area Start */}
                  <div className="col-md-8 blog-page-content">
                    {/* Blog Item Start */}
                    <div className="blog-item">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="blog-item-img">
                            <a href="#">
                              <img
                                src="img/blog-img/img-2.jpg"
                                alt=""
                                className="img-responsive"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="blog-item-content">
                            <h2>
                              <a href="#">Lorem ipsum dolor</a>
                            </h2>
                            <div className="metadata">
                              <i className="fa fa-calendar" />
                              <a href="#">16/03/2016</a>
                              <span className="divider"> | </span>
                              <i className="fa fa-user" />
                              <a href="#">Themelooks</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Earum neque voluptates
                              laudantium ea, mollitia excepturi non velit
                              debitis temporibus natus ex distinctio accusamus
                              animi suscipit, quas fuga nemo at
                              perferendis.Lorem ipsum dolor sit amet,
                              consectetur adipisicing elit. Voluptate tempore
                              porro labore.
                            </p>
                            <a href="#" className="btn btn-custom-reverse">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Blog Item End */}
                    <hr />
                    {/* Blog Item Start */}
                    <div className="blog-item">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="blog-item-img">
                            <a href="#">
                              <img
                                src="img/blog-img/img-3.jpg"
                                alt=""
                                className="img-responsive"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="blog-item-content">
                            <h2>
                              <a href="#">Lorem ipsum dolor</a>
                            </h2>
                            <div className="metadata">
                              <i className="fa fa-calendar" />
                              <a href="#">16/03/2016</a>
                              <span className="divider"> | </span>
                              <i className="fa fa-user" />
                              <a href="#">Themelooks</a>
                            </div>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit. Earum neque voluptates
                              laudantium ea, mollitia excepturi non velit
                              debitis temporibus natus ex distinctio accusamus
                              animi suscipit, quas fuga nemo at
                              perferendis.Lorem ipsum dolor sit amet,
                              consectetur adipisicing elit. Voluptate tempore
                              porro labore.
                            </p>
                            <a href="#" className="btn btn-custom-reverse">
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Blog Item End */}
                    <hr />
                  </div>
                  {/* Blog Content Area End */}
                </div>
              </div>
            </div>
            {/* Blog Area End */}
        </>
      </div>
  );
}
