/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import URL from "constant/url";
// import { useNavigate } from "react-router-dom";

import URL from "constant/url";

export default function Footer() {
  // const navigate = useNavigate();
  // const domain = window.location.hostname;
  // const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;

  return (
    <section className="padding_bottom_75 footer02_section bg_eef6fe">
      <div className="container">
        <div className="row padding_bottom_20">
          <div className="col-xl-9 col-lg-9 col-md-12 padding_top_10 footer02_list_link">
            <ul className="footer02_links_list">
              <li>
                <a href="/">Trang chủ</a>
              </li>
              <li>
                <a href="/list-server-vn">VPS</a>
              </li>
              <li>
                <a href="/list-dedicated-server-eu">Dedicated Server</a>
              </li>
              <li>
                <a href="/list-proxy-vn">Proxy</a>
              </li>
            </ul>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-12 text-right footer02_chat_btn">
            <a href="tel:+(84)973884503" className="chat_btn_footer02">
              Liên hệ
            </a>
          </div>
        </div>
        <div className="footer02_center_box padding_top_75 padding_bottom_75">
          <div className="row">
            <div
              className="col-xl-4 col-lg-4 col-md-12 footer02_content_box"
            >
              <figure className="footer02_logo">
                <img
                  src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                  alt=""
                />
              </figure>
              <p className="margin_bottom_30" style={{ fontSize: "16px" }}>
                Công Ty TNHH Công Nghệ HTTVServer
              </p>
              <p style={{ fontSize: "16px" }}>
                Số ĐKKD: 0109688271, cấp ngày 30/06/2021, tại sở KHĐT Hà Nội{" "}
                <br />
                Đại diện pháp luật: Giám đốc Trịnh Xuân Hòa
              </p>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 footer02_contact_list"
              style={{ fontSize: "16px" }}
            >
              <h5 className="footer_heading">Liên hệ</h5>
              <ul className="footer01_contact_list">
                <li style={{ fontSize: "16px" }}>
                  30 ngõ 102 Tô Vĩnh Diện, phường Khương Trung, quận Thanh Xuân,
                  Hà Nội
                </li>
                <li style={{ fontSize: "16px" }}>
                  <div>
                    Phone: <a href="tel:+ (84)973884503">+ (84)973884503</a>
                  </div>
                  <div>
                    Telegram: <a href="https://t.me/hoatrinh34">@hoatrinh34</a>
                  </div>
                </li>
                <li style={{ fontSize: "16px" }}>
                  <div>
                    <a href="mailto:admin@httvserver.com">
                      admin@httvserver.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-3 padding_left_40 footer02_link_box"
            >
              <h5 className="footer_heading">Danh mục</h5>
              <ul className="footer01_links_list">
                <li>
                  <a href={URL.Agreement} style={{ fontSize: "16px" }}>Điều khoản</a>
                </li>
                <li>
                  <a href={URL.SLA} style={{ fontSize: "16px" }}>SLA</a>
                </li>
                <li>
                  <a href={URL.Guide} style={{ fontSize: "16px" }}>Hướng dẫn</a>
                </li>
                <li>
                  <a href={URL.Contact} style={{ fontSize: "16px" }}>Liên hệ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row padding_top_30 footer02_outer_copyright">
          <div className="copyright_box float-left">
            <a href="http://online.gov.vn/Home/WebDetails/84770">
              Copyright © Since 2021 HTTVServer, All rights reserved
            </a>
          </div>
          <div className="ml-auto outer_footer01_social_links">
            <ul className="footer01_social_links">
              <li>
                <a href="https://www.facebook.com/VPSchinhhanggiare">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
