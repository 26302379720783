/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ProxyCategory() {
  const navigate = useNavigate();
  return (
    <div
      className="dropdown-menu default_mega_menu"
      style={{ width: "auto", padding: "30px" }}
      aria-labelledby="navbarDropdown"
    >
      <div style={{ gap: "30px", display: "flex" }}>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div
              className="mega_menu_box_heading"
              style={{ whiteSpace: "nowrap" }}
            >
              KHU VỰC VIỆT NAM
            </div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyVN);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/vietnam.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Việt Nam</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div
              className="mega_menu_box_heading"
              style={{ whiteSpace: "nowrap" }}
            >
              KHU VỰC CHÂU Á
            </div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxySG);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/singapore.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>
                    Proxy Singapore (SG)
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyAU);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/australia.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Úc (AU)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box padding_left_30">
          <div className="sub_mega_menu_box">
            <div
              className="mega_menu_box_heading"
              style={{ whiteSpace: "nowrap" }}
            >
              KHU VỰC CHÂU ÂU
            </div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyUK);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/united-kingdom.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Anh (UK)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyDE);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/germany.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Đức (DE)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyFR);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/france.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Pháp (FR)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box padding_left_30">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div
              className="mega_menu_box_heading"
              style={{ whiteSpace: "nowrap" }}
            >
              KHU VỰC CHÂU MỸ
            </div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyUS);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/united-states.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Mỹ (US)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListProxyCA);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/canada.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>Proxy Canada (CA)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
