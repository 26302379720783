/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <div
      className="dropdown-menu default_mega_menu"
      style={{ width: 'auto', padding: "30px" }}
      aria-labelledby="navbarDropdown"
    >
      <div style={{ gap: "20px", display: "flex", flexWrap: 'wrap' }}>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div className="mega_menu_box_heading">KHU VỰC VIỆT NAM</div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerVN);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/vietnam.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Việt Nam (VN)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div className="mega_menu_box_heading">KHU VỰC CHÂU Á</div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerSG);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/singapore.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>
                    VPS Singapore (SG)
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerAU);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/australia.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Úc (AU)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box">
            <div className="mega_menu_box_heading">KHU VỰC CHÂU ÂU</div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerUK);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/united-kingdom.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Anh (UK)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerDE);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/germany.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Đức (DE)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerFR);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/france.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Pháp (FR)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div className="mega_menu_box_heading">KHU VỰC CHÂU MỸ</div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerUS);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/united-states.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Mỹ (US)</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item pt-0 d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerCA);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/canada.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Canada (CA)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mega_menu_box">
          <div className="sub_mega_menu_box margin_mega_menu">
            <div className="mega_menu_box_heading">THEO MỤC ĐÍCH</div>
            <ul className="list_mega_menu_box">
              <li>
                <a
                  className="dropdown-item d-flex"
                  href=""
                  onClick={() => {
                    navigate(URL.ListServerEU);
                  }}
                >
                  <img
                    style={{
                      alignSelf: "center",
                      marginRight: "6px",
                    }}
                    src="assets/flags/european-union.png"
                    alt="server"
                    width={35}
                    height={35}
                  ></img>
                  <span style={{ alignSelf: "center" }}>VPS Châu Âu (PTU)</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
